.go-wrapper {
  display: flex;
  justify-content: center;
  margin-left: -240px;
}

.go-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 20px;
  width: 200px;
}

.go-menu > * {
  height: 100px;
  margin: 10px 0;
}

.go-menu * {
  border: 1px solid darkgrey;
}

.go-menu div {
  border: 0;
}

.go-menu > span {
  align-items: center;
  display: flex;
  justify-content: center;
}
